<script>
    // -- IMPORTS

    import { getImagePath } from '$lib/filePath';
    import AdminEditButton from '$lib/component/element/AdminEditButton.svelte';
    import { enabledAdminView } from '$lib/store/adminStore.js';
    import { getLocalizedText } from 'senselogic-gist';
    import { languageTagStore } from '$lib/store/languageTagStore';
    import AnimatedContainer from '$lib/component/element/AnimatedContainer.svelte';
    import { getProcessedMultilineTranslatedText } from '../../base';
    import ContactButtons from '../element/ContactButtons.svelte';
  import { onMount } from 'svelte';

    // -- VARIABLES

    export let managerArray;

    let isExpanded = {};
    let hasOverflow = {};

    // -- FUNCTIONS
    
    function toggleReadMore(
        biographyId
        ) 
    {
        isExpanded = { ...isExpanded, [ biographyId ]: !isExpanded[ biographyId ] };
    }

    // -- STATEMENTS

    onMount(() => {
        managerArray.forEach(({ id }) => {
            let element = document.getElementById(`biography-${id}`);

            if ( element ) 
            {
                hasOverflow[id] = element.scrollHeight > element.clientHeight;
            }
        });
    });
</script>

<style lang="stylus">
    // -- IMPORTS

    @import '../../../constant.styl';
    @import '../../../mixin.styl';

    // -- CLASSES

    .manager-section
    {
        border: 1px solid borderColor;
        padding: 5rem 1.5rem;

        display: flex;
        flex-direction: column;
        gap: 4rem;

        +media( desktop )
        {
            margin: 0;
            margin-bottom: 5rem;
            max-width: 77vw;
            border: none;
            padding-bottom: 0;
        }
    }

    .manager-card
    {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        align-items: flex-start;
        align-self: stretch;

        +media( desktop )
        {
            flex-direction: row;
            gap: 5rem;
        }
    }

    .manager-card.reverse
    {
        +media( desktop )
        {
            flex-direction: row-reverse;
        }
    }

    .manager-card-content
    {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        align-items: flex-start;
        align-self: stretch;

        +media( desktop )
        {
            width: 100%;

            flex: 1 0 0;
            gap: 2rem;
            justify-content: center;
        }
    }

    .manager-card-content-text
    {
        line-height: 2rem;
        font-size: 1.25rem;
        color: #BFC6CA;
    }

    .manager-card-heading-title
    {
        line-height: 3rem;
        font-size: 2rem;
        font-weight: 600;
        color: whiteColor;
    }

    .manager-card-image
    {
        height: auto;
        max-width: 32rem;
        aspect-ratio: 1;

        align-self: stretch;
        object-fit: cover;
        object-position: center;

        filter: grayscale(100%);

        +media( desktop )
        {
            height: 30rem;
            aspect-ratio: 4 / 3;
        }
    }

    .description-text
    {
        position: relative;
        overflow: hidden;

        display: -webkit-box;
        -webkit-box-orient: vertical;
        
        max-height: 8rem;

        transition: all .3s;
    }

    .description-text.expanded
    {
        max-height: 100%;
    }
</style>

<section class="manager-section">
    {#each managerArray as { id, name, position, imagePath, biography, imageSide, linkedinLink, emailAddress, instagramLink } }
        <AnimatedContainer>
            <div class="manager-card { imageSide === 'right' ? 'reverse' : '' }" class:is-admin-enabled={ $enabledAdminView }>
                <AdminEditButton
                    type={ "manager" }
                    slug={ id }
                />
                <div class="manager-card-image"
                    style="background: URL({ getImagePath( imagePath, '1920' ) }) no-repeat center center / cover,
                                       URL({ getImagePath( imagePath, '360' ) }) no-repeat center center / cover;"
                />

                <div class="manager-card-content">
                    <div>
                        <p class="manager-card-heading-title">{ name }</p>
                        <p class="manager-card-content-text">{ getLocalizedText( position, $languageTagStore ) }</p>
                    </div>

                    <div id={`biography-${id}`} class="description-text { isExpanded[id] ? 'expanded' : '' }">
                        {@html getProcessedMultilineTranslatedText( biography || '', $languageTagStore )}
                    </div>

                    {#if hasOverflow[id] }
                        <span 
                        on:click={() => toggleReadMore(id)} 
                        style="cursor: pointer; text-decoration: underline;"
                        >
                            {
                                isExpanded[id] 
                                    ? 'Read Less' 
                                    : 'Read More'
                            }
                        </span>
                    {/if }

                    <ContactButtons { emailAddress } { linkedinLink } { instagramLink } />
                </div>
            </div>
        </AnimatedContainer>
    {/each}
</section>
