<script>
    // -- IMPORTS

    import { onMount } from 'svelte';
    import {
        selectedPlan,
        selectedExtraService,
        selectedFee,
        selectedCustomizedFromBamhubService,
        selectedServiceOfferedByValBusin,
        selectedServiceOfferedViaBam
    } from '$lib/store/planStore.js';
    import AccordionItem from '$lib/component/Dropdown/AccordionItem.svelte';
    import AccordionList from '$lib/component/Dropdown/AccordionList.svelte';
    import { enabledAdminView } from '$lib/store/adminStore.js';
    import { getLocalizedText } from 'senselogic-gist';
    import { getProcessedMultilineTranslatedText } from '$lib/base';
    import { navigate } from 'svelte-routing';
    import { languageTagStore } from '$lib/store/languageTagStore';
    import { slide } from 'svelte/transition';
    import { textStore } from '$lib/store/textStore';
    import AdminEditButton from '$lib/component/element/AdminEditButton.svelte';
    import { membershipStore } from '../store/membershipStore';
    import { fetchData } from '../base';
    import FullLoading from '../component/Layout/FullLoading.svelte';
    import urlStore from '../store/urlStore';

    // -- CONSTANTS

    let periodSlugByTypeMap =
        {
            'monthlyFee': $textStore[ 'month-label' ] || '',
            'annualFee': $textStore[ 'year-label' ] || ''
        };

    let serviceStoreByExtraServiceTypeMap =
        {
            'customized-service-type': selectedExtraService,
            'service-offered-by-val-e-busin-type': selectedServiceOfferedByValBusin,
            'service-offered-via-bam-type': selectedServiceOfferedViaBam,
            'customized-service-from-bamhub-type': selectedCustomizedFromBamhubService,
        };

    // -- VARIABLES

    let isLoading = true;
    let activeStep = 0;
    let isIAmAwareChecked = false;
    let successModalIsOpen = false;
    let container;
    let planArray = $membershipStore.data.planArray;
    let membershipExtraServiceArray = $membershipStore.data.membershipExtraServiceArray;
    let activeAccordionIndex = null;

    // -- FUNCTIONS

    async function loadData(
        )
    {
        try
        {
            isLoading = true;

            let BecomeMemberData = await fetchData(
                        '/api/become-member',
                        {
                            method: 'POST',
                            body:  JSON.stringify(
                                {
                                    extraServiceType: '',
                                    isInflated: true,
                                    isEqual: false,
                                }
                            ),
                            headers: { "Content-type": 'application/json' }
                        }
                    );

            $membershipStore.data.planArray = BecomeMemberData.planArray;
            $membershipStore.data.membershipExtraServiceArray = BecomeMemberData.membershipExtraServiceArray;

            planArray = BecomeMemberData.planArray;
            membershipExtraServiceArray = BecomeMemberData.membershipExtraServiceArray;
        }
        catch ( error )
        {
            console.error( 'Error:', error )
        }
        finally
        {
            isLoading = false;
        }
    }

    // ~~

    function handleChangeAccordionIndex(
        index
        )
    {
        activeAccordionIndex = activeAccordionIndex === index ? null : index;
    }

    // ~~

    function handleChangeCheckbox(
        service,
        planLevel
        )
    {
        let membershipLevelId = service.membershipLevelId;

        if ( membershipLevelId !== $selectedPlan?.id )
        {
            selectedPlan.set( planLevel );

            let storeToUpdate = serviceStoreByExtraServiceTypeMap[ service.extraServiceType ];

            storeToUpdate.update(
                ( extraServiceArray ) => extraServiceArray.concat( service.id )
                );
        }
    }

    // ~~

    async function handleSubmit(
        event
        )
    {
        event.preventDefault();

        let formData = new FormData(event.target);
        formData.append( 'planId', JSON.stringify( $selectedPlan.id || '' ) );
        formData.append(
            'extraService',
            JSON.stringify(
                [].concat(
                    $selectedExtraService,
                    $selectedCustomizedFromBamhubService,
                    $selectedServiceOfferedByValBusin,
                    $selectedServiceOfferedViaBam
                    )
                )
            );
        formData.append( 'selectedFee', $selectedFee );

        try
        {
            let response
                = await fetchData(
                    '/api/book-a-call',
                    {
                        method: 'POST',
                        body: formData,
                    }
                    );

            if ( response.status === 200 )
            {
                successModalIsOpen = true;
            }
            else
            {
                console.error( 'Form submission failed:', response.statusText );
            }
        }
        catch ( error )
        {
            console.error('An error occurred:', error);
        }
    }

    // -- STATEMENTS

    onMount(
        () =>
        {
            if( planArray === undefined
                || membershipExtraServiceArray === undefined
            )
            {
                loadData()
            }
            else
            {
                isLoading = false;
            }
        }
    );

    $: if ( $urlStore.pathname.includes( 'become-member' ) )
        {
            document.title = 'Become Member';
        }
</script>

<style lang="stylus">
    // -- IMPORTS

    @import '../../constant.styl'
    @import '../../mixin.styl'

    // -- ELEMENTS

    :global( html > body > div > main )
    {
        background: unset;
        background-color: darkGrayColor;
    }

    h1
    {
        text-transform: uppercase;
    }

    section
    {
        margin-bottom: 5rem;
        max-width: 100%;

        +media( desktop )
        {
            margin-bottom: unset;
            max-width: 77vw;
        }
    }

    form
    {
        width: 100%;

        display: flex;
        flex-direction: column;
        gap: 2rem;
        align-items: center;
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active
    {
        -webkit-box-shadow: 0 0 0 40px grayColor500 inset !important;
        -webkit-text-fill-color: lightGreyColor !important;
    }

    input,
    textarea,
    select
    {
        width: 100%;
        padding: 1rem 1.5rem;

        background-color: grayColor500;

        font-size: 1.25rem;
        color: lightGreyColor;

        +media( desktop )
        {
            min-width: 24.875rem;
        }
    }

    textarea
    {
        min-height: 7.75rem;

        resize: none;
    }

    p
    {
        +media( smaller-desktop )
        {
            text-align: left;
        }
    }

    // -- CLASSES

    .book-a-call-heading
    {
        max-width: 77vw;
        padding: 2.5rem 0 0;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        text-align: center;

        +media( smaller-desktop )
        {
            margin-bottom: 4rem;
        }
    }

    .book-a-call-heading p
    {
        text-align: center;
    }

    .company-information-container
    {
        max-width: 51.25rem;

        display: flex;
        flex-direction: column;
        gap: 1.5rem;
    }

    .company-information-container.hidden
    {
        display: none;
    }

    .company-information-inputs-container
    {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;

        +media( desktop )
        {
            gap: 3rem;
        }
    }

    .label-and-input-container
    {
        width: 100%;

        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        justify-content: space-between;
    }

    .inputs-container
    {
        width: 100%;

        display: flex;
        gap: 1.5rem;

        +media( smaller-desktop )
        {
            flex-direction: column;
        }
    }

    .modal-overlay
    {
        z-index: 999
        position: fixed;

        inset: 0 0;

        background-color: rgba( 0, 0, 0, 0.5 );
    }

    .success-modal-container
    {
        z-index: 1000;
        position: fixed;
        bottom: 0;

        height: 100vh;
        width: 100%;
        padding: 1.5rem 1.5rem 4rem 1.5rem;

        display: flex;
        flex-direction: column;
        gap: 1.5rem;
        align-items: center;

        background-color: grayColor500;
        box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.40);

        +media( desktop )
        {
            inset: auto;
            height: auto;
            width: 50rem;
            padding: 3rem 3rem 4rem 3rem;

            gap: 1rem;
        }
    }

    .button-container
    {
        max-width: 51.25rem;

        display: flex;
        flex-direction: column;
        gap: 1.5rem;
    }

    .text-nowrap
    {
        text-wrap: wrap;
        line-height: 2.25rem;
        font-size: 1.5rem;
        font-weight: 400;

        +media( desktop )
        {
            text-wrap: nowrap;
            line-height: 5rem;
            font-size: 3.5rem;
            font-weight: 250;
        }
    }

    input,
    select
    {
        outline: none;
    }

    .terms-and-privacy-text
    {
        line-height: 1.75rem;
        font-size: 1.25rem;
        font-weight: 300;
        text-decoration-line: underline;
        color: lightGreyColor;
    }

    .i-am-aware-container
    {
        width: 100%;
        max-width: 51.25rem;

        display: flex;
        gap: 0.5rem;
    }

    .i-am-aware-container > label
    {
        line-height: 2.025rem;
        font-size: 1.375rem;
        color: lightGreyColor;
    }

    .i-am-aware-container > input
    {
        width: 1.5rem;
        min-width: 1.5rem;
        border: 2px solid lightGoldColor;
        accent-color: lightGoldColor;
        filter: invert(1);
    }

    .i-am-aware-label
    {
        flex: 1;
    }

    .close-confirmation-modal-button
    {
        height: 3rem;
        width: 3rem;
        border: 1px solid lightGoldColor;

        display: flex;
        justify-content: center;
        align-items: center;
        align-self: flex-end;

        background: grayColor500;

        cursor: pointer;
    }

    .success-modal-content
    {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        justify-content: center;
        align-items: center;
        align-self: stretch;
    }

    .success-modal-content > h2
    {
        line-height: 3rem;
        font-size: 2rem;
        font-weight: 500;
        letter-spacing: 0.08rem;
        text-align: center;
        text-transform: uppercase;
        color: lightGoldColor;

        +media( desktop )
        {
            line-height: 5rem;
            font-size: 4rem;
            font-weight: 250;
        }
    }

    .success-modal-content > span
    {
        line-height: 2.25rem;
        font-size: 1.5rem;
        font-weight: 400;
        color: lightGreyColor;
    }

    .icon,
    .close-confirmation-modal-button
    {
        flex-shrink: 0;
    }

    .checkbox-wrapper input[ type="checkbox" ]
    {
        display: none;

        visibility: hidden;
    }

    .checkbox-wrapper .cbx
    {
        margin: auto;
        -webkit-user-select: none;
        display: flex;
        gap: 0.5rem;

        user-select: none;
        cursor: pointer;
    }

    .checkbox-wrapper .cbx span
    {
        transform: translate3d( 0, 0, 0 );

        display: inline-block;
        align-items: center;

        line-height: 2rem;
        font-size: 1.25rem;
        vertical-align: middle;
        color: lightGreyColor;
    }

    .checkbox-wrapper .cbx span:last-child
    {
        margin-left: 0.5rem;
    }

    .checkbox-wrapper .cbx span:first-child
    {
        position: relative;
        transform: scale( 1 );

        margin-top: 0.25rem;
        height: 1.5rem;
        width: 1.5rem;
        border: 2px solid grayColor600;

        display: flex;
        flex-shrink: 0;
        justify-content: center;
        align-items: center;

        vertical-align: middle;

        transition: all 0.2s ease;
    }

    .checkbox-wrapper .cbx span:first-child svg
    {
        position: absolute;
        fill: none;
        stroke: darkGreyColor;
        stroke-width: 2;
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke-dasharray: 1rem;
        stroke-dashoffset: 1rem;
        transform: translate3d( 0, 0, 0 );

        transition: all 0.3s ease;
        transition-delay: 0.1s;
    }

    .checkbox-wrapper .cbx span:first-child:before
    {
        transform: scale( 0 );

        height: 100%;
        width: 100%;
        border-radius: 50%;

        display: block;

        content: "";
        opacity: 1;
        background: lightGoldColor;
    }

    .checkbox-wrapper .cbx:hover span:first-child
    {
        border-color: lightGoldColor;
    }

    .checkbox-wrapper .inp-cbx:checked + .cbx span:first-child
    {
        border-color: lightGoldColor;

        background: lightGoldColor;

        animation: wave 0.4s ease;
    }

    .checkbox-wrapper .inp-cbx:checked + .cbx span:first-child svg
    {
        stroke-dashoffset: 0;
    }

    .checkbox-wrapper .inp-cbx:checked + .cbx span:first-child:before
    {
        transform: scale( 3.5 );

        opacity: 0;

        transition: all 0.6s ease;
    }

    // -- MIXINS

    @keyframes wave
    {
        50%
        {
            transform: scale( 0.9 );
        }
    }
</style>

<svelte:head>
    <title>Become Member</title>

    <meta name="description" content={ `Become a member of BAMHUB, Where "My friend is your friend' comes into its own.` } />
</svelte:head>

{#if isLoading }
    <FullLoading />
{:else}
    {#if successModalIsOpen }
        <div class="modal-overlay" />
        <div class='success-modal-container' transition:slide>
            <button
                class="close-confirmation-modal-button"
                on:click={
                    () =>
                    {
                        successModalIsOpen = false;
                        navigate( `/${ $languageTagStore }/` );
                    }
                }
            >
                <div class="light-gold-x-icon size-150 icon"></div>
            </button>
            <div class="success-modal-content">
                <div class="check-circle-icon size-600"/>
                <h2 class:is-admin-enabled={ $enabledAdminView }>
                    <AdminEditButton
                        type="text"
                        slug="confirmation-label"
                    />
                    { getLocalizedText( $textStore[ 'confirmation-label' ] || '' ) }
                </h2>
                <span class:is-admin-enabled={ $enabledAdminView }>
                    <AdminEditButton
                        type="text"
                        slug="confirmation-text"
                    />
                    { getLocalizedText( $textStore[ 'confirmation-text' ] || '' ) }
                </span>
            </div>
        </div>
    {/if}

    <div class="book-a-call-heading">
        <h1 class="color-light-gold" class:is-admin-enabled={ $enabledAdminView }>
            {#if activeStep === 1 }
                { getLocalizedText( $textStore[ 'confirmation-label' ] || '', $languageTagStore ) }
                <AdminEditButton
                    type="text"
                    slug="confirmation-label"
                />
            {:else}
                { getLocalizedText( $textStore[ 'become-member-label' ] || '' ) }
                <AdminEditButton
                    type="text"
                    slug="become-member-label"
                />
            {/if}
        </h1>
        <p class:is-admin-enabled={ $enabledAdminView }>
            { getLocalizedText( $textStore[ 'book-a-call-subtitle' ] || '', $languageTagStore ) }
            <AdminEditButton
                type="text"
                slug="book-a-call-subtitle"
            />
        </p>
    </div>

    <section>
        <form
            on:submit={ handleSubmit }
        >
            <div class="company-information-container" class:hidden={ activeStep === 1 }>
                <h2 class="color-light-gold text-nowrap" class:is-admin-enabled={ $enabledAdminView }>
                    { getLocalizedText( $textStore[ 'contractor-information-label' ] || '', $languageTagStore ) }
                    <AdminEditButton
                        type="text"
                        slug="contractor-information-label"
                    />
                </h2>
                <div class="company-information-inputs-container">
                    {#await import( '$lib/component/Dropdown/Dropdown.svelte' ) then { default : Dropdown } }
                        <div class="inputs-container">
                            <Dropdown
                                label={
                                        `${
                                        getLocalizedText(
                                            $textStore[ 'selected-membership-label' ]
                                            || '',
                                            $languageTagStore
                                            )
                                        } *`
                                    }
                                placeholder={
                                    $selectedPlan?.name
                                    ? getLocalizedText( $selectedPlan?.name || '', $languageTagStore ) + '/' + getLocalizedText( periodSlugByTypeMap[ $selectedFee ] || '', $languageTagStore )
                                    : ''
                                    }
                            >
                                <AccordionList>
                                    {#each membershipExtraServiceArray as planLevel, planIndex ( planLevel.id ) }
                                        <AccordionItem
                                            text={ getLocalizedText( planLevel.name || '', $languageTagStore ) }
                                            disabled={ planLevel.id !== $selectedPlan?.id }
                                            isOpen={ planIndex === activeAccordionIndex }
                                            on:click={ () => handleChangeAccordionIndex( planIndex ) }
                                            isSelected={ planLevel.id === $selectedPlan?.id }
                                        >
                                            {#each planLevel.serviceArray as service ( service.id ) }
                                                <div class="checkbox-wrapper">
                                                    {#if service.extraServiceType === 'customized-service-from-bamhub-type' }
                                                    <input
                                                        type="checkbox"
                                                        class="inp-cbx"
                                                        bind:group={
                                                            $selectedCustomizedFromBamhubService
                                                        }
                                                        on:change={ () => handleChangeCheckbox( service, planLevel ) }
                                                        value={ service.id }
                                                        id={ service.id }
                                                        name={ service.serviceType.id }
                                                    />
                                                    {:else if service.extraServiceType === 'customized-service-type' }
                                                    <input
                                                        type="checkbox"
                                                        class="inp-cbx"
                                                        bind:group={
                                                            $selectedExtraService
                                                        }
                                                        on:change={ () => handleChangeCheckbox( service, planLevel ) }
                                                        value={ service.id }
                                                        id={ service.id }
                                                        name={ service.serviceType.id }
                                                    />
                                                    {:else if service.extraServiceType === 'service-offered-by-val-e-busin-type' }
                                                    <input
                                                        type="checkbox"
                                                        class="inp-cbx"
                                                        bind:group={
                                                            $selectedServiceOfferedByValBusin
                                                        }
                                                        on:change={ () => handleChangeCheckbox( service, planLevel ) }
                                                        value={ service.id }
                                                        id={ service.id }
                                                        name={ service.serviceType.id }
                                                    />
                                                    {:else}
                                                    <input
                                                        type="checkbox"
                                                        class="inp-cbx"
                                                        bind:group={
                                                            $selectedServiceOfferedViaBam
                                                        }
                                                        on:change={ () => handleChangeCheckbox( service, planLevel ) }
                                                        value={ service.id }
                                                        id={ service.id }
                                                        name={ service.serviceType.id }
                                                    />
                                                    {/if}
                                                    <label for={ service.id } class="cbx">
                                                        <span>
                                                        <svg viewBox="0 0 12 10" height="12px" width="1rem">
                                                            <polyline points="1.5 6 4.5 9 10.5 1"></polyline></svg
                                                        >
                                                        </span>
                                                        <span>{@html getProcessedMultilineTranslatedText( service.serviceType.name || '', { count : service.value + ' ' + service.serviceType.valueSuffix }, $languageTagStore ) }</span>
                                                    </label>
                                                </div>
                                            {/each}
                                        </AccordionItem>
                                    {/each}
                                </AccordionList>
                            </Dropdown>
                            <AdminEditButton
                                type="text"
                                slug="selected-membership-label"
                            />
                        </div>
                    {/await}
                    <div class="inputs-container">
                        <label class="label-and-input-container" class:is-admin-enabled={ $enabledAdminView }>
                            <div class="font-size-125 font-weight-600">
                                { getLocalizedText( $textStore[ 'first-name-label' ] || '' , $languageTagStore ) } *
                            </div>
                            <input
                                required
                                name="first-name"
                                placeholder={ getLocalizedText( $textStore[ 'first-name-label' ] || '', $languageTagStore ) }
                            />
                            <AdminEditButton
                                type="text"
                                slug="first-name-label"
                            />
                        </label>
                        <label class="label-and-input-container" class:is-admin-enabled={ $enabledAdminView }>
                            <div class="font-size-125 font-weight-600">
                                { getLocalizedText( $textStore[ 'last-name-label' ] || '', $languageTagStore ) } *
                            </div>
                            <input
                                required
                                name="last-name"
                                placeholder={ getLocalizedText( $textStore[ 'last-name-label' ] || '', $languageTagStore ) }
                            />
                            <AdminEditButton
                                type="text"
                                slug="last-name-label"
                            />
                        </label>
                    </div>
                    <div class="inputs-container">
                        <label class="label-and-input-container" class:is-admin-enabled={ $enabledAdminView }>
                            <div class="font-size-125 font-weight-600">
                                { getLocalizedText( $textStore[ 'title-label' ] || '', $languageTagStore ) }
                            </div>
                            <input
                                type="text"
                                name="title"
                                placeholder={ getLocalizedText( $textStore[ 'title-label' ] || '', $languageTagStore ) }
                            />
                            <AdminEditButton
                                type="text"
                                slug="title-label"
                            />
                        </label>
                        <label class="label-and-input-container" class:is-admin-enabled={ $enabledAdminView }>
                            <div class="font-size-125 font-weight-600">
                                { getLocalizedText( $textStore[ 'position-label' ] || '', $languageTagStore ) } *
                            </div>
                            <input
                                required
                                type="text"
                                name="position"
                                placeholder={ getLocalizedText( $textStore[ 'position-label' ] || '', $languageTagStore ) }
                            />
                            <AdminEditButton
                                type="text"
                                slug="position-label"
                            />
                        </label>
                    </div>
                    <div class="inputs-container">
                        <label class="label-and-input-container" class:is-admin-enabled={ $enabledAdminView }>
                            <div class="font-size-125 font-weight-600">
                                { getLocalizedText( $textStore[ 'email-label' ] || '', $languageTagStore ) } *
                            </div>
                            <input
                                required
                                type="email"
                                name="email"
                                placeholder={ getLocalizedText( $textStore[ 'email-label' ] || '', $languageTagStore ) }
                            />
                            <AdminEditButton
                                type="text"
                                slug="email-label"
                            />
                        </label>
                        <label class="label-and-input-container" class:is-admin-enabled={ $enabledAdminView }>
                            <div class="font-size-125 font-weight-600">
                                { getLocalizedText( $textStore[ 'phone-label' ] || '', $languageTagStore ) } *
                            </div>
                            <input
                                required
                                type="tel"
                                name="phone"
                                placeholder={ getLocalizedText( $textStore[ 'phone-label' ] || '' , $languageTagStore ) }
                            />
                            <AdminEditButton
                                type="text"
                                slug="phone-label"
                            />
                        </label>
                    </div>
                    <div class="inputs-container">
                        <label class="label-and-input-container" class:is-admin-enabled={ $enabledAdminView }>
                            <div class="font-size-125 font-weight-600">
                                { getLocalizedText( $textStore[ 'linkedin-label' ] || '' , $languageTagStore ) }
                            </div>
                            <input
                                name="linkedin"
                                placeholder={ getLocalizedText( $textStore[ 'linkedin-label' ] || '', $languageTagStore ) }
                            />
                            <AdminEditButton
                                type="text"
                                slug="linkedin-label"
                            />
                        </label>
                    </div>
                    <h2 class="color-light-gold text-nowrap font-size-350" class:is-admin-enabled={ $enabledAdminView }>
                        { getLocalizedText( $textStore[ 'company-information-label' ] || '', $languageTagStore ) }
                        <AdminEditButton
                            type="text"
                            slug="company-information-label"
                        />
                    </h2>
                    <div class="inputs-container">
                        <label class="label-and-input-container" class:is-admin-enabled={ $enabledAdminView }>
                            <div class="font-size-125 font-weight-600">
                                { getLocalizedText( $textStore[ 'company-name-label' ] || '' , $languageTagStore ) } *
                            </div>
                            <input
                                required
                                name="company-name"
                                placeholder={ getLocalizedText(  $textStore[ 'company-name-label' ] || '' , $languageTagStore ) }
                            />
                            <AdminEditButton
                                type="text"
                                slug="company-name-label"
                            />
                        </label>
                        <label class="label-and-input-container" class:is-admin-enabled={ $enabledAdminView }>
                            <div class="font-size-125 font-weight-600">
                                { getLocalizedText(  $textStore[ 'type-organization-label' ] || '' , $languageTagStore ) } *
                            </div>
                            <input
                                required
                                name="type-of-organization"
                                placeholder={ getLocalizedText( $textStore[ 'type-organization-label' ] || '', $languageTagStore ) }
                            />
                            <AdminEditButton
                                type="text"
                                slug="type-organization-label"
                            />
                        </label>
                    </div>
                    <div class="inputs-container">
                        <label class="label-and-input-container" class:is-admin-enabled={ $enabledAdminView }>
                            <div class="font-size-125 font-weight-600">
                                { getLocalizedText( $textStore[ 'industry-label' ] || '' , $languageTagStore ) }
                            </div>
                            <input
                                name="industry"
                                placeholder={ getLocalizedText(  $textStore[ 'industry-label' ] || '' , $languageTagStore ) }
                            />
                            <AdminEditButton
                                type="text"
                                slug="industry-label"
                            />
                        </label>
                        <label class="label-and-input-container" class:is-admin-enabled={ $enabledAdminView }>
                            <div class="font-size-125 font-weight-600">
                                { getLocalizedText(  $textStore[ 'cnpj-label' ] || '' , $languageTagStore ) }
                            </div>
                            <input
                                name="cnpj"
                                placeholder="00.000.000/0000-00"
                            />
                            <AdminEditButton
                                type="text"
                                slug="cnpj-label"
                            />
                        </label>
                    </div>
                    <div class="inputs-container">
                        <label class="label-and-input-container" class:is-admin-enabled={ $enabledAdminView }>
                            <div class="font-size-125 font-weight-600">
                                { getLocalizedText( $textStore[ 'company-activity-label' ] || '' , $languageTagStore ) }
                            </div>
                            <input
                                name="company-activity"
                                placeholder={ getLocalizedText(  $textStore[ 'company-activity-label' ] || '' , $languageTagStore ) }
                            />
                            <AdminEditButton
                                type="text"
                                slug="company-activity-label"
                            />
                        </label>
                    </div>
                    <div class="inputs-container">
                        <label class="label-and-input-container" class:is-admin-enabled={ $enabledAdminView }>
                            <div class="font-size-125 font-weight-600">
                                { getLocalizedText( $textStore[ 'site-internet-label' ] || '' , $languageTagStore ) } *
                            </div>
                            <input
                                required
                                name="site-internet"
                                placeholder={ getLocalizedText(  $textStore[ 'site-internet-label' ] || '' , $languageTagStore ) }
                            />
                            <AdminEditButton
                                type="text"
                                slug="site-internet-label"
                            />
                        </label>
                    </div>
                    <div class="inputs-container">
                        <label class="label-and-input-container" class:is-admin-enabled={ $enabledAdminView }>
                            <div class="font-size-125 font-weight-600">
                                { getLocalizedText( $textStore[ 'company-linkedin-label' ] || '' , $languageTagStore ) }
                            </div>
                            <input
                                name="company-linkedin"
                                placeholder={ getLocalizedText(  $textStore[ 'company-linkedin-label' ] || '' , $languageTagStore ) }
                            />
                            <AdminEditButton
                                type="text"
                                slug="company-linkedin-label"
                            />
                        </label>
                    </div>

                    <div class="inputs-container">
                        <label class="label-and-input-container" class:is-admin-enabled={ $enabledAdminView }>
                            <div class="font-size-125 font-weight-600">
                                { getLocalizedText( $textStore[ 'street-number-label' ] || '' , $languageTagStore ) }
                            </div>
                            <input
                                name="street-number"
                                placeholder={ getLocalizedText(  $textStore[ 'street-number-label' ] || '' , $languageTagStore ) }
                            />
                            <AdminEditButton
                                type="text"
                                slug="street-number-label"
                            />
                        </label>
                        <label class="label-and-input-container" class:is-admin-enabled={ $enabledAdminView }>
                            <div class="font-size-125 font-weight-600">
                                { getLocalizedText( $textStore[ 'street-label' ] || '' , $languageTagStore ) }
                            </div>
                            <input
                                name="street"
                                placeholder={ getLocalizedText(  $textStore[ 'street-label' ] || '' , $languageTagStore ) }
                            />
                            <AdminEditButton
                                type="text"
                                slug="street-label"
                            />
                        </label>
                    </div>

                    <div class="inputs-container">
                        <label class="label-and-input-container" class:is-admin-enabled={ $enabledAdminView }>
                            <div class="font-size-125 font-weight-600">
                                { getLocalizedText( $textStore[ 'zip-code-label' ] || '' , $languageTagStore ) }
                            </div>
                            <input
                                name="zipcode"
                                placeholder={ getLocalizedText(  $textStore[ 'zip-code-label' ] || '' , $languageTagStore ) }
                            />
                            <AdminEditButton
                                type="text"
                                slug="zip-code-label"
                            />
                        </label>
                        <label class="label-and-input-container" class:is-admin-enabled={ $enabledAdminView }>
                            <div class="font-size-125 font-weight-600">
                                { getLocalizedText( $textStore[ 'state-label' ] || '' , $languageTagStore ) }
                            </div>
                            <input
                                name="state"
                                placeholder={ getLocalizedText(  $textStore[ 'state-label' ] || '' , $languageTagStore ) }
                            />
                            <AdminEditButton
                                type="text"
                                slug="state-label"
                            />
                        </label>
                    </div>

                    <div class="inputs-container">
                        <label class="label-and-input-container" class:is-admin-enabled={ $enabledAdminView }>
                            <div class="font-size-125 font-weight-600">
                                { getLocalizedText( $textStore[ 'city-label' ] || '' , $languageTagStore ) } *
                            </div>
                            <input
                                required
                                name="city"
                                placeholder={ getLocalizedText(  $textStore[ 'city-label' ] || '' , $languageTagStore ) }
                            />
                            <AdminEditButton
                                type="text"
                                slug="city-label"
                            />
                        </label>
                        <label class="label-and-input-container" class:is-admin-enabled={ $enabledAdminView }>
                            <div class="font-size-125 font-weight-600">
                                { getLocalizedText( $textStore[ 'country-label' ] || '' , $languageTagStore ) } *
                            </div>
                            <input
                                required
                                name="country"
                                placeholder={ getLocalizedText(  $textStore[ 'country-label' ] || '' , $languageTagStore ) }
                            />
                            <AdminEditButton
                                type="text"
                                slug="country-label"
                            />
                        </label>
                    </div>

                    <div class="inputs-container">
                        <label class="label-and-input-container" class:is-admin-enabled={ $enabledAdminView }>
                            <div class="font-size-125 font-weight-600">
                                { getLocalizedText(  $textStore[ 'anything-else-label' ] || '' , $languageTagStore ) }
                            </div>
                            <textarea
                                name="other"
                                placeholder={ getLocalizedText( $textStore[ 'type-here-label' ] || '', $languageTagStore ) }
                            />
                            <AdminEditButton
                                type="text"
                                slug="type-here-label"
                            />
                        </label>
                    </div>
                    <p class:is-admin-enabled={ $enabledAdminView }>
                        {@html getProcessedMultilineTranslatedText( $textStore[ 'contact-us-text' ], $languageTagStore ) }
                        <AdminEditButton
                            type="text"
                            slug="contact-us-text"
                        />
                    </p>
                </div>
            </div>
            <div class="i-am-aware-container" class:is-admin-enabled={ $enabledAdminView }>
                <input type="checkbox" name="i-am-aware" id="i-am-aware" bind:checked={ isIAmAwareChecked }>
                <label for="i-am-aware" class="i-am-aware-label">
                    { getLocalizedText( $textStore[ 'i-am-aware-label' ] || '', $languageTagStore ) }
                </label>
                <AdminEditButton
                    type="text"
                    slug="i-am-aware-label"
                />
            </div>
            <div class="button-container">
                <p  class:is-admin-enabled={ $enabledAdminView }>
                    { getLocalizedText( $textStore[ 'by-continuing-label' ] || '', $languageTagStore ) }
                    <AdminEditButton
                        type="text"
                        slug="by-continuing-label"
                    />
                    <a class="terms-and-privacy-text" href="/{ $languageTagStore }/terms-of-service" class:is-admin-enabled={ $enabledAdminView }>
                        { getLocalizedText( $textStore[ 'terms-conditions-label' ] || '', $languageTagStore ) }
                        <AdminEditButton
                            type="text"
                            slug="terms-conditions-label"
                        />
                    </a>
                    { getLocalizedText( $textStore[ 'acknowledge-label' ], $languageTagStore ) }
                    <a class="terms-and-privacy-text" href="/{ $languageTagStore }/privacy-policy" class:is-admin-enabled={ $enabledAdminView }>
                        { getLocalizedText( $textStore[ 'privacy-policy-label' ] || '', $languageTagStore ) }
                        <AdminEditButton
                            type="text"
                            slug="acknowledge-label"
                        />
                    </a>
                </p>
                {#await import( '$lib/component/element/Button.svelte' ) then { default : Button } }
                    <Button
                        type="submit"
                        disabled={ !isIAmAwareChecked }
                        on:click={
                            () =>
                            {
                                if ( container )
                                {
                                    window.scrollTo(
                                        {
                                            top: container.offsetTop - 100
                                        }
                                        );
                                }
                            }
                        }
                    >
                        { getLocalizedText( $textStore[ 'continue-label' ] || '', $languageTagStore ) }
                        <AdminEditButton
                            type="text"
                            slug="continue-label"
                        />
                    </Button>
                {/await}
            </div>
        </form>
    </section>
{/if}
