<script>
    // -- IMPORTS

    import { getImagePath } from '$lib/filePath';
    import { getLocalizedText } from 'senselogic-gist';
    import { languageTagStore } from '$lib/store/languageTagStore';
    import { Capacitor } from '@capacitor/core';

    // -- VARIABLES

    export let markerIndex = 0;
    export let marker;
    export let scale = 1;
    let markerCardContainerElement;
    let isZoomedIn = window.visualViewport.scale > 1;

    // -- FUNCTIONS
    

    function handleOpenMarkerCard(
        event
        )
    {
        let existingOpenCard = document.querySelector('.is-hover');

        if (  existingOpenCard 
              && existingOpenCard !== markerCardContainerElement ) 
        {
            existingOpenCard.classList.remove('is-hover');
        }

        markerCardContainerElement.classList.add( 'is-hover' );
    }

    // ~~

    function handleCloseMarkerCard(
        event
        )
    {
        if ( markerCardContainerElement !== null )
        {
            if ( screen.width > 1064 )
            {
                markerCardContainerElement.classList.remove( 'is-hover' );
                markerCardContainerElement.style.left = '';
                markerCardContainerElement.style.top = '';
            }

            if ( 
                markerCardContainerElement.classList.contains( 'is-hover' ) 
                && event.target.closest( ":not(.marker-card)" ) 
                && !event.target.closest('.marker-container') )
            {
                markerCardContainerElement.classList.remove( 'is-hover' );
                markerCardContainerElement.style.left = '';
                markerCardContainerElement.style.top = '';
            }
        }
    }

    // ~~

    function handleResize() {
        isZoomedIn = window.visualViewport.scale > 1;
    }

    // -- STATEMENTS

    window.visualViewport.addEventListener( "resize", handleResize );

    $: if ( isZoomedIn === false )
       {
          window.addEventListener( "touchstart", handleCloseMarkerCard );
       }
       else
       {
            window.removeEventListener( "touchstart", handleCloseMarkerCard );
       }
</script>

<style lang="stylus">
    // -- IMPORTS

    @import '../../../constant.styl';
    @import '../../../mixin.styl';

    // -- CLASSES

    .is-hover
    {
        transform: translateY( 0 );

        display: inherit;

        visibility: visible;
        opacity: 1;

        pointer-events: none;
    }

    .marker-card-container:not( .is-hover )
    {
        transform: translateY( 1rem );

        display: none;

        visibility: hidden;
        opacity: 0;

        pointer-events: none;
    }

    .marker-container
    {
        position: absolute;
        transform: translate( -50%, -100% );
        z-index: 2;

        display: flex;
        flex-direction: column;
        flex-shrink: 0;
        gap: -40px;
        justify-content: flex-end;
        align-items: center;

        font-size: 1rem;

        cursor: pointer;
        transition: transform 400ms ease-in-out, opacity 500ms ease-in-out, visibility 400ms ease-in-out;
    }

    .marker-card-container
    {
        position: relative;
        z-index: 2;

        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .marker-content
    {
        transform: rotate(-45deg);

        border-radius: 16px;
        padding: 1px;

        display: flex;
        gap: 8px;
        justify-content: center;
        align-items: center;

        background: rgba( 173, 166, 144, 0.00 );

        transition: padding 400ms ease-in-out, background 400ms ease-in-out;

        +media( desktop )
        {
            animation: pulse 2s ease-in-out infinite;
            animation-delay: calc( var( --animation-delay ) * 0.5s );
        }
    }

    .marker-content:hover
    {
        padding: 2px;

        background: rgba( 173, 166, 144, 0.40 );

        animation: none;
    }

    .marker-content:hover .marker
    {
        padding: .25rem;
    }

    .marker
    {
        z-index: 1;

        border-radius: 100%;
        padding: 0.1rem;

        display: flex;
        gap: 8px;
        align-items: center;

        background: lightGoldColor;

        transition: padding 400ms ease-in-out;

        +media( desktop )
        {
            padding: 0.18rem;
        }
    }

    .marker-card
    {
        position: absolute;

        border: 1px solid lightGoldColor;
        border-radius: 1.25rem;
        padding: 1rem;

        display: flex;
        flex-direction: column;
        gap: .5rem;
        align-items: flex-start;

        background: darkGreyColor;

        +media( desktop )
        {
            position: initial;
        }
    }

    .marker-card-image
    {
        height: 6rem;
        width: 8.5rem;
        border-radius: 10px;

        align-self: stretch;

        +media( desktop )
        {
            width: 10.5rem;
        }
    }

    .marker-card-description
    {
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: flex-start;
    }

    .title
    {
        line-height: 2.1rem;
        font-size: 1.5rem;
        font-weight: 600;
        font-style: normal;
        letter-spacing: 0.015rem;
        color: lightGoldColor;
        text-align: center;
    }

    .subtitle
    {
        align-self: stretch;

        line-height: 2rem;
        font-size: 1.1rem;
        color: lightGreyColor;
    }

    .marker-card-polygone
    {
        display: none;

        +media( desktop )
        {
            margin-top: -16px;
            height: 12px;
            width: 12px;
            fill: linear-gradient( 0deg, rgba( 0, 0, 0, 0.20 ) 0%, rgba( 0, 0, 0, 0.20 ) 100% ), #000;
        }
    }

    // -- MIXINS

    @keyframes pulse
    {
        0%, 100%
        {
            border: 0px solid rgba(173, 166, 144, 0);

            background: rgba(173, 166, 144, 0.00);
        }

        50%
        {
            border: .5px solid rgba(173, 166, 144, 1);

            background: rgba(173, 166, 144, 0.50);
        }
    }
</style>

<div class="marker-container" style="left: { marker.x }%; top: { marker.y }%">
    <div class="marker-card-container" bind:this={ markerCardContainerElement }>
        <div 
            class="marker-card" 
            on:click|stopPropagation
            >
            {#if marker.imagePath }
                <div class="marker-card-image" style="background: url( { getImagePath( marker.imagePath, '640' ) } ) lightgray 50% / cover no-repeat;"/>
            {/if}
            <div class="marker-card-description">
                {#if marker.name }
                    <p class="title">{ getLocalizedText( marker.name, $languageTagStore ) }</p>
                {/if}

                {#if marker.subtitle }
                    <p class="subtitle">{ marker.subtitle }</p>
                {/if}
            </div>
        </div>
        <svg class="marker-card-polygone" xmlns="http://www.w3.org/2000/svg" width="42" height="37" viewBox="0 0 42 37" fill="none">
            <path d="M21 36.3726L0.215396 0.372555L41.7846 0.372558L21 36.3726Z" fill="black"/>
            <path d="M21 36.3726L0.215396 0.372555L41.7846 0.372558L21 36.3726Z" fill="black" fill-opacity="0.2"/>
        </svg>
    </div>
    <div 
        class="marker-content" 
        style="--animation-delay: { markerIndex };"
        on:touchstart={ handleOpenMarkerCard }
        on:mouseenter={ handleOpenMarkerCard } 
        on:mouseleave={ handleCloseMarkerCard } 
        >
        <div 
            class="marker" 
            >
            <!-- <svg class="logo" xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                <path d="M6.61099 0.0147095H11.5V4.90372H6.61099V0.0147095ZM6.61099 6.1257H11.5V11.0147H6.61099V6.1257ZM0.5 0.0147095H2.9445C4.29448 0.0147095 5.38901 1.10924 5.38901 2.45921C5.38901 3.80919 4.29448 4.90372 2.9445 4.90372H0.5V0.0147095ZM0.5 6.1257H2.9445C4.29448 6.1257 5.38901 7.22023 5.38901 8.57021C5.38901 9.92018 4.29448 11.0147 2.9445 11.0147H0.5V6.1257Z" fill="#1B1B1B"/>
            </svg> -->
        </div>
    </div>
</div>
